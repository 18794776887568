import { useState, useEffect } from "react";

import './Courses.css';

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetPurchase, RegisterPurchase, SetPurchaseSingle } from "interface/Purchase";

import Page_Contact from "page/Contact";

export default function Page_Courses(props){

    const [ search, setSearch ] = useState('');
    const [ courses, setCourses ] = useState(GetDataPage('courses'));
    const [ purchase, setPurchase ] = useState(GetPurchase('purchase'));

    function InputSearch(value){
        const newList = [];
        if(value){
            GetDataPage('courses').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setCourses(duplicate);
        }else {
            setCourses(GetDataPage('courses'));
        }
        setSearch(value);
    }

    function OpenCourse(id, cover, title, text, type_video, video, price, discount){
        SetModalData('InfCourse', { "id": id, "cover": cover, "title": title, "text": text, "type_video": type_video, "video": video, "price": price, "discount": discount });
        SetModalState('InfCourse', true)
    }

    function AddPurchase(type, id, cover, title, text, discount, price){
        SetPurchaseSingle(type, id, cover, title, text, discount, price);
    }

    useEffect(()=>{
        RegisterPurchase('purchase', setPurchase);
    }, []);

    return(
        <>
            <div className="page_content page_courses">
                <div className="container">
                    <div className="div_search">
                        <input type="text" className="input_search" onChange={ (e)=>{ InputSearch(e.target.value) } } value={ search } placeholder="digite aqui sua pesquisa..." />
                    </div>

                    <div className="div_courses">
                        {
                            courses.length > 0 ?
                                courses.map((elem, index)=>{
                                    let indexId = GetPurchase('purchase').find(item => item.id == elem.id);

                                    return(
                                        <div className="inf_course" key={ index }>
                                            <div className="img_cover" style={ { backgroundImage: 'url("' + elem.cover + '")' } } />
                                            <div className="details_course">
                                                <div className="name_course">{ elem.title }</div>
                                                <div className="description_course" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                                <div className="color_text" />
                                                
                                                <div className="div_purchase div_mobile">
                                                    <div className="show_price">
                                                        {
                                                            elem.discount !='' ?
                                                                <>
                                                                    <span className="span_price">R$ { elem.price }</span>
                                                                    <span className="discount">R$ { elem.discount }</span>
                                                                </>
                                                            :
                                                            <span>R$ { elem.price }</span>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                <div className="div_purchase">
                                                    <div className="div_btn_course">
                                                        {
                                                            indexId ?
                                                            <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } }>
                                                                Curso adicionado
                                                            </div>
                                                            :
                                                            <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } } onClick={ ()=>{ AddPurchase('add_purchase', elem.id, elem.cover, elem.title, elem.text, elem.discount, elem.price); } }>
                                                                Adicionar ao carrinho
                                                            </div>
                                                        }

                                                        <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } } onClick={ ()=>{ OpenCourse(elem.id, elem.cover, elem.title, elem.text, elem.type_video, elem.video, elem.price, elem.discount) } }>
                                                            Informações sobre o curso
                                                        </div>
                                                    </div>
                                                    <div className="show_price hide_data">
                                                        {
                                                            elem.discount !='' ?
                                                                <>
                                                                    <span className="span_price">R$ { elem.price }</span>
                                                                    <span className="discount">R$ { elem.discount }</span>
                                                                </>
                                                            :
                                                            <span>R$ { elem.price }</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            : <div className="no_data">Nenhum dado encontrado...</div>
                        }
                    </div>
                </div>
            </div>
            
            <Page_Contact dataConfig={ props.dataConfig } ReturnIndex={ props.ReturnIndex } setLoading={ props.setLoading } />
        </>
    )
}