import { useState } from "react";

import './Contact.css';

import TextareaAutosize from 'react-textarea-autosize';

import { SetModalData, SetModalState } from "interface/PopUp";

import { Reg_Contact } from "services/Register";
import { Svg_Facebook, Svg_Instagram, Svg_Linkedin, Svg_Whatsapp, Svg_Youtube } from "services/SvgFile";

export default function Page_Contact(props){

    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ toKnow, setToKnow ] = useState('');
    const [ text, setText ] = useState('');
    
    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        if(email.includes('@') && email.includes('.')){
            Reg_Contact('contact', name, email, phone, toKnow, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }else {
            EmailInvalided();
        }
    }

    function EmailInvalided(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": "E-mail inválido!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackSuccess(){
        props.setLoading(false);
        setName('');
        setEmail('');
        setPhone('');
        setToKnow('');
        setText('');
        
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }    

    return(
        <div className="page_content page_contact" id="contato" style={ { background: props.dataConfig['color_contact'] } }>
            <div className="container">
                <div className="title_page">Contato</div>
                <div className="show_data_contact">
                    <div className="div_form">
                        <form className="form" onSubmit={ SaveData }>
                            <div className="wave-group">
                                <input type="text" className="input" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required />
                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>N</span>
                                    <span className="label-char" style={ { '--index': 1 } }>o</span>
                                    <span className="label-char" style={ { '--index': 2 } }>m</span>
                                    <span className="label-char" style={ { '--index': 3 } }>e</span>
                                    <span className="label-char" style={ { '--index': 4, paddingLeft: "6px" } } />
                                    <span className="label-char" style={ { '--index': 5 } }>c</span>
                                    <span className="label-char" style={ { '--index': 6 } }>o</span>
                                    <span className="label-char" style={ { '--index': 7 } }>m</span>
                                    <span className="label-char" style={ { '--index': 8 } }>p</span>
                                    <span className="label-char" style={ { '--index': 9 } }>l</span>
                                    <span className="label-char" style={ { '--index': 10 } }>e</span>
                                    <span className="label-char" style={ { '--index': 11 } }>t</span>
                                    <span className="label-char" style={ { '--index': 12 } }>o</span>
                                </label>
                            </div>

                            <div className="wave-group">
                                <input type="email" className="input" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required />
                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>E</span>
                                    <span className="label-char" style={ { '--index': 1 } }>-</span>
                                    <span className="label-char" style={ { '--index': 2 } }>m</span>
                                    <span className="label-char" style={ { '--index': 3 } }>a</span>
                                    <span className="label-char" style={ { '--index': 4 } }>i</span>
                                    <span className="label-char" style={ { '--index': 5 } }>l</span>
                                </label>
                            </div>

                            <div className="wave-group">
                                <input type="text" className="input" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phone } required />
                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>C</span>
                                    <span className="label-char" style={ { '--index': 1 } }>e</span>
                                    <span className="label-char" style={ { '--index': 2 } }>l</span>
                                    <span className="label-char" style={ { '--index': 3 } }>u</span>
                                    <span className="label-char" style={ { '--index': 4 } }>l</span>
                                    <span className="label-char" style={ { '--index': 5 } }>a</span>
                                    <span className="label-char" style={ { '--index': 6 } }>r</span>
                                </label>
                            </div>

                            <div className="wave-group">
                                <input type="text" className="input" onChange={ (e)=>{ setToKnow(e.target.value) } } value={ toKnow } required />
                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>C</span>
                                    <span className="label-char" style={ { '--index': 1 } }>o</span>
                                    <span className="label-char" style={ { '--index': 2 } }>m</span>
                                    <span className="label-char" style={ { '--index': 3 } }>o</span>
                                    <span className="label-char" style={ { '--index': 4, paddingLeft: "6px" } } />
                                    <span className="label-char" style={ { '--index': 5 } }>n</span>
                                    <span className="label-char" style={ { '--index': 6 } }>o</span>
                                    <span className="label-char" style={ { '--index': 7 } }>s</span>
                                    <span className="label-char" style={ { '--index': 8, paddingLeft: "6px" } } />
                                    <span className="label-char" style={ { '--index': 9 } }>c</span>
                                    <span className="label-char" style={ { '--index': 10 } }>o</span>
                                    <span className="label-char" style={ { '--index': 11 } }>n</span>
                                    <span className="label-char" style={ { '--index': 12 } }>h</span>
                                    <span className="label-char" style={ { '--index': 13 } }>e</span>
                                    <span className="label-char" style={ { '--index': 14 } }>c</span>
                                    <span className="label-char" style={ { '--index': 15 } }>e</span>
                                    <span className="label-char" style={ { '--index': 16 } }>u</span>
                                    <span className="label-char" style={ { '--index': 17 } }>?</span>
                                </label>
                            </div>

                            <div className="wave-group">
                                <TextareaAutosize className="input textarea" onChange={ (e)=>{ setText(e.target.value) } } value={ text.replace(/<br>/g,'\n') } required />

                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>M</span>
                                    <span className="label-char" style={ { '--index': 1 } }>e</span>
                                    <span className="label-char" style={ { '--index': 2 } }>n</span>
                                    <span className="label-char" style={ { '--index': 3 } }>s</span>
                                    <span className="label-char" style={ { '--index': 4 } }>a</span>
                                    <span className="label-char" style={ { '--index': 5 } }>g</span>
                                    <span className="label-char" style={ { '--index': 6 } }>e</span>
                                    <span className="label-char" style={ { '--index': 7 } }>m</span>
                                </label>
                            </div>

                            <div className="div_btn">
                                <button type="submit" className="btn" style={ { background: props.dataConfig['color_btn'] } }>Enviar</button>
                            </div>
                        </form>
                    </div>

                    <div className="data_text">
                        <div className="text">Empresa: { props.dataConfig['company_name'] }</div>
                        <div className="text">E-mail: { props.dataConfig['email'] }</div>
                        {
                            props.dataConfig['text'] === "" ? null :
                            <div className="text" dangerouslySetInnerHTML={ { __html: props.dataConfig['text'].replaceAll('&#34;', '"') } } />
                        }

                        <div className="div_icons">
                            {
                                props.dataConfig['linkedin'] == "" ? null : 
                                <a href={ props.dataConfig['linkedin'] } target="_blank">
                                    <Svg_Linkedin className="img" color="#ffffff" />
                                </a>
                            }
                            {
                                props.dataConfig['instagram'] == "" ? null : 
                                <a href={ props.dataConfig['instagram'] } target="_blank">
                                    <Svg_Instagram className="img" color="#ffffff" />
                                </a>
                            }
                            {
                                props.dataConfig['facebook'] == "" ? null : 
                                <a href={ props.dataConfig['facebook'] } target="_blank">
                                    <Svg_Facebook className="img" color="#ffffff" />
                                </a>
                            }
                            {
                                props.dataConfig['youtube'] == "" ? null : 
                                <a href={ props.dataConfig['youtube'] } target="_blank">
                                    <Svg_Youtube className="img" color="#ffffff" />
                                </a>
                            }
                            {
                                props.dataConfig['whatsapp'] == "" ? null : 
                                <a href={ "https://wa.me/+55" + props.dataConfig['whatsapp'] } target="_blank">
                                    <Svg_Whatsapp className="img" color="#ffffff" />
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}