import { useState, useEffect } from "react";

import './Header.css';

import { Link, useLocation } from "react-router-dom";

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { GetPurchase, RegisterPurchase } from "interface/Purchase";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { Svg_Purchase, Svg_User } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function Comp_Header(props){

    const location = useLocation();
    const [ status, setStatus ] = useState(false);
    const [ openCloseMenu, setOpenCloseMenu ] = useState(false);
    const [ optMenu, setOptMenu ] = useState(GetDataPage('name_menu'));
    const [ idUser, setIdUser ] = useState(GetUserData('id'));  
    const [ purchase, setPurchase ] = useState(GetPurchase('purchase'));
    const [ colorPurchase, setColorPurchase ] = useState(colorIcon);
    const [ colorTextPurchase, setColorTextPurchase ] = useState("#ffffff");
    
    function OpenOptDiv(id){
        SetListPag('page', id);
        const scrollInterval = setInterval(() => {            
            const element = document.getElementById(id);
            if (element) {
                clearInterval(scrollInterval);   
                let yOffset = -60; 
                if(window.innerWidth > 768){
                    yOffset += -10;
                } 
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }          
        }, 200);
    }

    function OpenCloseMenu(value){
        setOpenCloseMenu(value);
        if(value){
            document.body.style.overflow = "hidden";
            document.getElementById('div_opt_menu').style.display = null;
            document.getElementById('div_opt_menu').classList.add('open_menu_mobile');

            const elements = document.querySelectorAll('.bars');
            elements.forEach(element => {
                element.classList.add('bars_new_color');
            });
            
        }else {
            document.body.style.overflow = null;
            document.getElementById('div_opt_menu').classList.add('close_menu_mobile');

            setTimeout(() => {
                const elements = document.querySelectorAll('.bars');
                elements.forEach(element => {
                    element.classList.remove('bars_new_color');
                });
            }, 700);

            setTimeout(() => {
                document.getElementById('div_opt_menu').classList.remove('open_menu_mobile');
                document.getElementById('div_opt_menu').classList.remove('close_menu_mobile');

                const elements = document.querySelectorAll('.bars');
                elements.forEach(element => {
                    element.classList.remove('bars_new_color');
                });

                if(window.innerWidth <= 900){
                    document.getElementById('div_opt_menu').style.display = "none";
                }else {
                    document.getElementById('div_opt_menu').style.display = null;
                }
            }, 1000);
        }
    }

    function ShowInfLogin(){
        if(props.statusLogin){
            let nameLogin = GetUserData('name').split(' ');
            return(
                <div className="div_inf_user">
                    <div className="div_img">
                        {
                            GetUserData('file') !='' ?
                            <div className="img_user" style={ { backgroundImage: 'url("' + GetUserData('file') + '")' } } /> :
                            <div className="">
                                <Svg_User color={ colorIcon } className="icon_menu_profile" />
                            </div>
                        }
                    </div>
                    <div className="div_name_user">
                        <div className="">Bem vindo</div>
                        <div className="">{ nameLogin[0] }</div>                        
                    </div>
                </div>
            )
        }else {
            return "Login"
        }
    }

    function AltPositionDiv(id){
        const ScrollInterval = setInterval(() => {
            const element = document.getElementById(id);
            if (element) {
                clearInterval(ScrollInterval);   
                let yOffset = -60; 
                if(window.innerWidth > 768){
                    yOffset += -10;
                } 
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }          
        }, 200);
    }

    useEffect(()=>{
        AltPositionDiv(location['pathname'].split('/')[1]);

        if(window.innerWidth <= 900){
            setStatus(true);
            setColorPurchase('#ffffff');
            setColorTextPurchase(colorIcon);
            document.getElementById('div_opt_menu').style.display = "none";
        }else {
            setStatus(false);   
            setColorPurchase(colorIcon); 
            setColorTextPurchase('#ffffff');         
            document.getElementById('div_opt_menu').style.display = null;
        }   

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 900){
                setStatus(true);
                setColorPurchase('#ffffff');
                setColorTextPurchase(colorIcon);
                document.getElementById('div_opt_menu').style.display = "none";
            }else {   
                setStatus(false);  
                setColorPurchase(colorIcon);   
                setColorTextPurchase('#ffffff');            
                document.getElementById('div_opt_menu').style.display = null;
            }
        });

        window.addEventListener("scroll", ()=>{
            if(window.scrollY >= 60){
                document.getElementById('header').classList.add('div_menu_scroll');
            }else {
                document.getElementById('header').classList.remove('div_menu_scroll');
            }
        });

        RegisterUserData('id', setIdUser);
        RegisterPurchase('purchase', setPurchase);

        return ()=>{
            UnRegisterUserPage('id', setIdUser);
        };
    }, []);
    
    return(
        <>
            <div className="HeaderFixed" id="return_up" />
            <div className="Header" id="header">
                <div className="container">

                    <Link to="/" className="div_logo" onClick={ ()=>{ SetListPag('page', 'index'); OpenCloseMenu(false); props.ReturnIndex(); } }>
                        <img alt="logotipo" src={ props.dataConfig['logo'] } className="logo" />
                    </Link>

                    {
                        status ? 
                            <div className="div_icon_menu">
                                <input type="checkbox" id="checkbox" onChange={ ()=>{ OpenCloseMenu(!openCloseMenu); } } checked={ openCloseMenu } />
                                <label htmlFor="checkbox" className="toggle">
                                    <div className="bars" id="bar1"></div>
                                    <div className="bars" id="bar2"></div>
                                    <div className="bars" id="bar3"></div>
                                </label>
                            </div>
                        : null
                    }

                    <div className="div_menu" id="div_opt_menu">
                        {
                            optMenu.map((elem, index)=>{
                                if(elem.page === "faq"){
                                    return(
                                        <Link to={ "/" + elem.page } className="show_name_menu" onClick={ ()=>{ OpenOptDiv(elem.page); OpenCloseMenu(false); props.ReturnIndex(); } } key={ index }>
                                            { elem.name }
                                            {
                                                location['pathname'].split('/')[1] === elem.page ? 
                                                <div className="menu_active" /> : null
                                            }
                                        </Link>
                                    );

                                }else if(elem.page === "home"){
                                    return(
                                        <Link to="/" className="show_name_menu" onClick={ ()=>{ SetListPag('page', 'index'); OpenCloseMenu(false); props.ReturnIndex(); } } key={ index }>
                                            { elem.name }
                                            {
                                                location['pathname'].split('/')[1] === "index" || location['pathname'].split('/')[1] === "" ? 
                                                <div className="menu_active" /> : null
                                            }
                                        </Link>
                                    )
                                }else {
                                    return(
                                        <Link to={ "/" + elem.page } className="show_name_menu" onClick={ ()=>{ OpenOptDiv(elem.page); OpenCloseMenu(false); props.ReturnIndex(); AltPositionDiv(elem.page) } } key={ index }>
                                            { elem.name }
                                            {
                                                location['pathname'].split('/')[1] === elem.page ? 
                                                <div className="menu_active" /> : null
                                            }
                                        </Link>
                                    );
                                }
                            })
                        }
                        
                        <span className="inf_login_purchase">
                            {
                                GetPurchase('purchase').length > 0 ? 
                                    <Link to="/carrinho_de_compra" className="div_purchase" onClick={ ()=>{ SetListPag('page', 'carrinho_de_compra'); OpenCloseMenu(false); props.ReturnIndex(); } }>
                                        <Svg_Purchase className="icons" color={ colorPurchase } />
                                        <div className="qtd_course" style={ { background: colorPurchase, color: colorTextPurchase } }>
                                            { GetPurchase('purchase').length }
                                        </div>
                                    </Link>
                                :
                                null
                            }
                            <Link to="/login" className={ props.statusLogin ? "show_name_menu_user" : "show_name_menu" } onClick={ ()=>{ SetListPag('page', 'login'); OpenCloseMenu(false); props.ReturnIndex(); } }>
                                { ShowInfLogin() }
                                {
                                    location['pathname'].split('/')[1] === "login" || GetDataPage('courses').find(item => item.url == location['pathname'].split('/')[1]) ? 
                                    <div className="menu_active" /> : null
                                }
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}