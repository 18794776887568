import { useState, useEffect } from "react";

import './Register.css';

import { SetModalData, SetModalState } from "interface/PopUp";

import { Svg_User } from "services/SvgFile";
import { Reg_SaveUser } from "services/Register";
import { cepMask, cpfMask, phoneMask } from "services/Mask";

import { colorIcon } from "fixedData";

import Page_Contact from "page/Contact";

export default function Page_Register(props){

    const [ file, setFile ] = useState('');
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ cpf, setCpf ] = useState('');
    const [ pass, setPass ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ cep, setCep ] = useState('');
    const [ city, setCity ] = useState('');
    const [ state, setState ] = useState('');
    const [ neighborhood, setNeighborhood ] = useState('');
    const [ street, setStreet ] = useState('');
    const [ number, setNumber ] = useState('');
    const [ complement, setComplement ] = useState('');

    function AddFile(value){
        if(value){
            setFile(value);
            setFileStatus(true);
        }else {
            setFile('');
            setFileStatus(false);
        }
    }

    function SaveData(event){
        props.setLoading(true);        
        event.preventDefault();
        Reg_SaveUser(0, file, name, email, cpf, pass, phone, cep, city, state, neighborhood, street, number, complement, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "type": "login", "text": "Informação registrada com sucesso!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "Erro ao registrar informações!" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <>
            <div className="page_content page_register">            
                <form className="container" onSubmit={ SaveData }>
                    <div className="title">Novo cadastro</div>
                    <div className="div_inf_user">
                        <div className="div_img_user">
                            {
                                fileStatus ?
                                <div className="btn_show_img" style={ { backgroundImage: 'url("' + URL.createObjectURL(file) + '")' } } />
                                :
                                <Svg_User color={ colorIcon } className="icon_show_user" />
                            }
                            <label className="btn_user" style={ { background: props.dataConfig['color_btn'] } }>
                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]) } } />
                                Alterar foto
                            </label>
                        </div>
                        <div className="div_show_inf">
                            <div className="type_data">
                                <div className="name_type_data">Meus dados</div>
                                <div className="show_inf_input">
                                    <div className="div_input space_div">
                                        <div className="name_input">Nome*</div>
                                        <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required={ true } />
                                    </div>
                                    <div className="div_input space_div">
                                        <div className="name_input">E-mail*</div>
                                        <input type="text" onChange={ (e)=>{ setEmail(e.target.value.replaceAll(' ', '')) } } value={ email } required={ true } />
                                    </div>
                                    <div className="div_input">
                                        <div className="name_input">CPF*</div>
                                        <input type="text" className="cpf" onChange={ (e)=>{ setCpf(e.target.value) } } value={ cpfMask(cpf) } required={ true } />
                                    </div>
                                    <div className="div_input">
                                        <div className="name_input">Contato*</div>
                                        <input type="text" className="phone" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phoneMask(phone) } required={ true } />
                                    </div>
                                    <div className="div_input">
                                        <div className="name_input">Senha*</div>
                                        <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } value={ pass } required={ true } />
                                    </div>
                                </div>
                            </div>
                            <div className="type_data">
                                <div className="name_type_data">Endereço</div>
                                <div className="show_inf_input">
                                    <div className="div_input">
                                        <div className="name_input">CEP</div>
                                        <input type="text" className="cep" onChange={ (e)=>{ setCep(e.target.value)  } } value={ cepMask(cep) } required={ false } placeholder="xxxxx-xx" />
                                    </div>
                                </div>
                                <div className="show_inf_input">
                                    <div className="div_input space_div">
                                        <div className="name_input">Cidade</div>
                                        <input type="text" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } maxLength={ 140 } required={ false } />
                                    </div>
                                    <div className="div_input">
                                        <div className="name_input">UF</div>
                                        <input type="text" className="uf" onChange={ (e)=>{ setState(e.target.value.replaceAll(' ', '')) } } value={ state } maxLength={ 3 } required={ false } />
                                    </div>
                                    <div className="div_input space_div">
                                        <div className="name_input">Bairro</div>
                                        <input type="text" className="" onChange={ (e)=>{ setNeighborhood(e.target.value) } } value={ neighborhood } maxLength={ 140 } required={ false } />
                                    </div>
                                    <div className="div_input space_div">
                                        <div className="name_input">Rua</div>
                                        <input type="text" className="" onChange={ (e)=>{ setStreet(e.target.value) } } value={ street } maxLength={ 140 } required={ false } />
                                    </div>
                                    <div className="div_input">
                                        <div className="name_input">Número</div>
                                        <input type="text" className="number" onChange={ (e)=>{ setNumber(e.target.value) } } value={ number } maxLength={ 140 } required={ false } placeholder="xxxxxx" />
                                    </div>
                                </div>
                                <div className="show_inf_input">
                                    <div className="div_input space_div">
                                        <div className="name_input">Complemento</div>
                                        <input type="text" className="" onChange={ (e)=>{ setComplement(e.target.value) } } value={ complement } required={ false } />
                                    </div>
                                </div>
                            </div>
                            <button className="btn_save" style={ { background: props.dataConfig['color_btn'] } }>Salvar</button>
                        </div>
                    </div>
                </form>
            </div>

            <Page_Contact dataConfig={ props.dataConfig } ReturnIndex={ props.ReturnIndex } setLoading={ props.setLoading } />
        </>
    )
}