import React, { useState, useEffect } from "react";

import './InfCourse.css';

import { Svg_Close } from "services/SvgFile";

import { GetPurchase, RegisterPurchase, SetPurchaseSingle } from "interface/Purchase";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

export default function PopUP_InfCourse(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ title, setTitle ] = useState('');
    const [ text, setText ] = useState('');
    const [ price, setPrice ] = useState('');
    const [ discount, setDiscount ] = useState('');
    const [ purchase, setPurchase ] = useState(GetPurchase('purchase'));

    function ClosePopUp(){
        setTitle('');
        setText('');
        setPrice('');
        setDiscount('');
        SetModalState('InfCourse', false);
    }

    function ShowVideo(){
        switch (modalData.type_video) {
            case 'Vimeo':
                let linkVimeo = modalData.video.split('/');
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://player.vimeo.com/video/" + linkVimeo[3] } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
                
            case 'Youtube':
                let linkYoutube = modalData.video.split('=');
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
        }
    }

    function AddPurchase(type, title, text, discount, price){
        SetPurchaseSingle(type, modalData.cover, modalData.id, title, text, discount, price);
    }

    useEffect(()=>{
        RegisterPurchase('purchase', setPurchase);
        RegisterModalData('InfCourse', setModaldata);
        RegisterModalObserver('InfCourse', setShowPopUp);
    }, []);

    useEffect(()=>{        
        if(showPopUp){
            setTitle(modalData.title);
            setText(modalData.text);
            setPrice(modalData.price);
            setDiscount(modalData.discount);
            document.body.style.overflow = "hidden";
        }else {
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    return(
        (showPopUp ?
            <div className="PopUp">
                <div className="all InfCourse">
                    <div className="div_data">
                        <div className="type_div">
                            <div className="">{ modalData.title }</div>
                            <div className="" onClick={ ()=>{ ClosePopUp(); } }>
                                <Svg_Close className="icons" color="#f00000" />
                            </div>
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <div className="show_video">
                                { ShowVideo() }
                            </div>
                            <div className="title">{ title }</div>
                            <div className="text" dangerouslySetInnerHTML={ { __html: text.replaceAll('&#34;', '"') } } />
                            <div className="show_price">                                
                                {
                                    discount !='' ?
                                        <>
                                            <span className="span_price">R$ { price }</span>
                                            <span className="discount">R$ { discount }</span>
                                        </>
                                    :
                                    <span>R$ { price }</span>
                                }
                            </div>
                            
                            {
                                GetPurchase('purchase').find(item => item.id == modalData.id) ?
                                <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } }>
                                    Curso adicionado
                                </div>
                                :
                                <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } } onClick={ ()=>{ AddPurchase('add_purchase', title, text, discount, price); } }>
                                    Adicionar ao carrinho
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    )
};
