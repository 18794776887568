import './Others.css';

import Comp_TextImg from "components/TextImg";

import Page_Contact from 'page/Contact';

export default function Page_Others(props){
    
    return(
        <>
            <div className="page_content">
                <Comp_TextImg data={ props.dataPage } dataConfig={ props.dataConfig } ReturnIndex={ props.ReturnIndex } />
            </div>
            
            <Page_Contact dataConfig={ props.dataConfig } ReturnIndex={ props.ReturnIndex } setLoading={ props.setLoading } />
        </>
    )
}