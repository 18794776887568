import { useState, useEffect } from 'react';

import './SlideShow_Video.css';

export default function Comp_SlideShow_Video(props){

    const [ status, setStatus ] = useState(false);

    useEffect(()=>{
        if(window.innerWidth <= 900){
            setStatus(true);
        }else {
            setStatus(false); 
        }   

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 900){
                setStatus(true);
            }else { 
                setStatus(false);  
            }
        });
    }, []);

    return(
        <div className="SlideShow_Video">
            <div className="show_img">
                <div className="container">
                    {
                        props.data['title'] == "" ? null :
                        <div className="title_slide title" data-text={ props.data['title'] }>
                            { props.data['title'] }
                        </div>
                    }
                    {
                        props.data['subtitle'] == "" ? null :
                        <div className="title_slide title subtitle" data-text={ props.data['subtitle'] }>
                            { props.data['subtitle'] }
                        </div>
                    }
                </div>

                <div className="video_background">
                    <video id="show_video" className="video" loop={ true } autoPlay="autoplay" controls={ false } muted>
                        <source src={ props.data['file'] } type="video/mp4" />
                    </video>
                    <div className="show_img_slide" style={ { backgroundImage: 'url("' + props.data['img_slide_show'] + '")' } } />
                </div>
            </div>
        </div>
    )
}