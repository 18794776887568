import { useState } from "react";

import './Faq.css';

import { GetDataPage } from "interface/Data";

import { Svg_Arrow } from "services/SvgFile";
import Page_Contact from "page/Contact";

export default function Page_Faq(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('faq'));

    function OpenDoubts(index, value){
        const newData = [...dataPage];
        newData[index]['status'] = value;
        setDataPage(newData);
        
        let accordion = document.getElementById('accordion_' + index); 
        if(value){
            accordion.style.maxHeight = accordion.scrollHeight + "px";
            accordion.style.padding = "12px 20px 20px";
        }else {
            accordion.style.maxHeight = null;
            accordion.style.padding = "12px 20px 0px"; 
        }   
    }

    return(
        <>
            <div className="page_content page_faq">
                <div className="container">
                    {
                        dataPage.map((elem, index)=>{
                            return(
                                <div className="div_faq" key={ index }>
                                    <div className="title" onClick={ ()=>{ OpenDoubts(index, !elem.status) } }>
                                        <div className="">{ elem.title }</div>
                                        <div className="">
                                            {
                                                elem.status ?
                                                <Svg_Arrow color="#FFFFFF" className="icons active" /> :
                                                <Svg_Arrow color="#FFFFFF" className="icons" />
                                            }
                                        </div>
                                    </div>
                                    <div id={ "accordion_" + index } className="text" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            
            <Page_Contact dataConfig={ props.dataConfig } ReturnIndex={ props.ReturnIndex } setLoading={ props.setLoading } />
        </>
    )
}