export function Svg_ChekedReturn(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
        </svg>
    )
}

export function Svg_Erro(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
        </svg>
    )
}

export function Svg_Instagram(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
        </svg>
    )
}

export function Svg_Purchase(props){
    return(
        <svg viewBox="0 0 31 36" className={ props.className } fill="none">
            <path fill={ props.color } d="M30.488 33.7788L28.2452 10.5694C28.184 9.93553 27.6123 9.45004 26.9276 9.45004H22.3025V7.51167C22.3025 3.89914 19.1398 0.960114 15.2524 0.960114C11.3651 0.960114 8.2028 3.89914 8.2028 7.51167V9.45004H3.57453C2.88978 9.45004 2.31811 9.93553 2.2569 10.5694L0.00528972 33.8701C-0.0278715 34.214 0.0959537 34.5548 0.346603 34.8095C0.597252 35.0642 0.951618 35.2093 1.32292 35.2093H29.1794C29.1804 35.2093 29.1818 35.2093 29.1829 35.2093C29.9137 35.2093 30.5058 34.6589 30.5058 33.98C30.5056 33.9116 30.4996 33.8442 30.488 33.7788ZM10.8486 7.51167C10.8486 5.25482 12.8242 3.41873 15.2526 3.41873C17.6811 3.41873 19.6568 5.25482 19.6568 7.51167V9.45004H10.8486V7.51167ZM2.77001 32.7508L4.78403 11.9087H8.2028V14.107C8.2028 14.7859 8.79494 15.3363 9.52572 15.3363C10.2565 15.3363 10.8486 14.7859 10.8486 14.107V11.9087H19.6568V14.107C19.6568 14.7859 20.249 15.3363 20.9797 15.3363C21.7105 15.3363 22.3027 14.7859 22.3027 14.107V11.9087H25.7183L27.7323 32.7508H2.77001Z" />
        </svg>
    )
}

export function Svg_Linkedin(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" />
        </svg>
    )
}

export function Svg_Facebook(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
        </svg>
    )
}

export function Svg_Youtube(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" />
        </svg>
    )
}

export function Svg_Whatsapp(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
        </svg>
    )
}

export function Svg_Arrow(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M9 18L15 12L9 6" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_Arrow_2(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M12,7L7,12H10V16H14V12H17L12,7Z"/>
        </svg>
    )
}

export function Svg_Close(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M18 6L6 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_Eye(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
        </svg>
    )
}

export function Svg_EyeClose(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" />
        </svg>
    )
}

export function Svg_ArrowRight(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M9 18L15 12L9 6" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_User(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color }  d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
        </svg>
    )
}

export function Svg_Play(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M10,16.5V7.5L16,12M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
        </svg>
    )
}

export function Svg_Download(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M8 17V15H16V17H8M16 10L12 14L8 10H10.5V7H13.5V10H16M5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3M5 5V19H19V5H5Z" />
        </svg>
    )
}

export function Svg_Lock(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
        </svg>
    )
}