
let DataPage = {
    "purchase" : []
};

let NotifyDataPage = {
    "purchase" : []
}

export function SetPurchase(value) {
    DataPage = value;
    NotifyDataPage["purchase"].forEach(element => { element(value['purchase']); });
}

export function SetPurchaseSingle(type, id, cover, title, text, discount, price) {  
    let index = DataPage['purchase'].findIndex(item => item.id == id);

    if(type === 'remuve'){ 
        DataPage['purchase'].splice(index, 1);

    }else if(type === 'add_purchase'){
        DataPage['purchase'].push({ "id": id, "cover": cover, "title": title, "text": text, "discount": discount, "price": price });
    }

    if(type == 'remuve_all'){ 
        DataPage['purchase'] = [];
        NotifyDataPage['purchase'].forEach(element => {
            element([]);        
        });
    }else {
        NotifyDataPage['purchase'].forEach(element => {
            element([{ "id": id, "cover": cover, "title": title, "text": text, "discount": discount, "price": price }]);        
        });
    }
}

export function GetPurchase(key){
    return DataPage[key];
}

export function RegisterPurchase(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
