import { useState, useEffect, useRef } from "react";

import './Login.css';

import { SetModalData, SetModalState } from "interface/PopUp";

import { Svg_ArrowRight, Svg_Eye, Svg_EyeClose } from "services/SvgFile";

import { colorIcon } from "fixedData";

import { Access } from "services/Access";

import { Link } from "react-router-dom";

import Page_Contact from "page/Contact";

export default function Page_Login(props){

    const divShowHidePassword = useRef();
    const [ statusShowHidePassword, setStatusShowHidePassword ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function ShowHidePass(status){
        setStatusShowHidePassword(status);
        divShowHidePassword.current.type = statusShowHidePassword ? 'password' : 'text';
    }
    
    function RecoverAccess(){
        SetModalState('RecoverEmail', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Access('logar', email, password, ()=>{ CallbackSuccess(); props.setStatusLogin(true) }, ()=>{ CallbackError(); props.setStatusLogin(false); });
    }

    function CallbackSuccess(){
        props.setLoading(false);
    }

    function CallbackError(){
        props.setLoading(false);
        setMsgError('E-mail ou senha incorreta!');
    }

    return(
        <>
            <form className="page_content page_login" onSubmit={ SaveData }>
                <div className="div_data_login">
                    <div className="show_data_login">
                        <div className="div_text">Bem vindo</div>
                        <div className="name_input">E-mail</div>
                        <div className="div_input">
                            <input type="email" onChange={ (e)=>{ setEmail(e.target.value.replaceAll(' ', '')) } } value={ email } required placeholder="digite seu e-mail..." />
                        </div>
                        <div className="name_input">Senha</div>
                        <div className="div_input">
                            <input type="password" onChange={ (e)=>{ setPassword(e.target.value.replaceAll(' ', '')) } } value={ password } ref={ divShowHidePassword } required placeholder="digite sua senha..." />
                            <div className="div_eye" onClick={ ()=>{ ShowHidePass(!statusShowHidePassword) } }>
                                {
                                    statusShowHidePassword ?
                                    <Svg_Eye className="icons" color={ colorIcon } /> :
                                    <Svg_EyeClose className="icons" color={ colorIcon } />
                                }
                            </div>
                        </div>
                        {
                            msgError ? <div className="div_input error">{ msgError }</div> : null
                        }
                        <div className="type_save">
                            <Link to="/novo_cadastro" className="div_recover register" onClick={ ()=>{ props.ReturnIndex(); } }>
                                Cadastrar-se
                            </Link>
                            
                            <button type="submit" className="div_btn" style={ { background: props.dataConfig['color_btn'] } }>
                                <div className="">Logar</div>
                                <div className="">
                                    <Svg_ArrowRight className="icons" color="#ffffff" />
                                </div>
                            </button>
                        </div>
                        <div className="type_save">
                            <div className="div_recover" onClick={ ()=>{ RecoverAccess() } }>Esqueceu a senha?</div>
                        </div>
                    </div>
                </div>
            </form>

            <Page_Contact dataConfig={ props.dataConfig } ReturnIndex={ props.ReturnIndex } setLoading={ props.setLoading } />
        </>
    )
}