import { useState, useEffect } from "react";

import './Purchase.css';
import { useNavigate } from "react-router-dom";

import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetPurchase, RegisterPurchase, SetPurchaseSingle } from "interface/Purchase";

import { Reg_Purchase } from "services/Register";
import Page_Contact from "page/Contact";

export default function Page_Purchase(props){

    const navigate = useNavigate();
    const [ showMsgPurchase, setShowMsgPurchase ] = useState(false);

    const [ purchase, setPurchase ] = useState(GetPurchase('purchase'));
    const [ price, setPrice ] = useState(PriceAll());

    function RemovePurchase(id){
        SetPurchaseSingle('remuve', id, "", "", "", "", "");
    }

    function OpenCourse(id, cover, title, text, type_video, video, price, discount){
        SetModalData('InfCourse', { "id": id, "cover": cover, "title": title, "text": text, "type_video": type_video, "video": video, "price": price, "discount": discount });
        SetModalState('InfCourse', true)
    }

    function PriceAll(){
        let newData = 0.00;
        GetPurchase('purchase').map((elem, index)=>{
            if(elem.discount){
                newData += parseFloat(elem.discount.replace(/\./g, '').replace(',', '.'));

            }else {
                newData += parseFloat(elem.price.replace(/\./g, '').replace(',', '.'));
            }
        })
        return newData.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    }

    function CheckedLogin(){
        console.log(props);
        
        if(props.statusLogin){
            Reg_Purchase(GetUserData('id'), GetPurchase('purchase'), ()=>{ AltPage() }, ()=>{ });

        }else {
            SetModalData('CheckedLogin', { "page": "erro", "text": "Compra realizada com sucesso!" });
            SetModalState('CheckedLogin', true);
        }
    }

    function AltPage(){
        setShowMsgPurchase(true);
        setTimeout(() => {
            setShowMsgPurchase(false);
            navigate("/login");
            SetPurchaseSingle('remuve_all', '', '', '', '', '', '');
        }, 1500);
    }

    useEffect(()=>{
        RegisterPurchase('purchase', setPurchase);
    }, []);

    return(
        <>
            <div className="page_content page_purchase">
                <div className="container">
                    <div className="div_border_bottom">
                        Lista dos cursos adicionado ao carrinho
                    </div>

                    <div className="div_courses">
                        {
                            GetPurchase('purchase').length > 0 ?
                                GetPurchase('purchase').map((elem, index)=>{
                                    
                                    return(
                                        <div className="inf_course" key={ index }>
                                            <div className="img_cover" style={ { backgroundImage: 'url("' + elem.cover + '")' } } />
                                            <div className="details_course">
                                                <div className="name_course">{ elem.title }</div>
                                                <div className="description_course" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                                <div className="color_text" />                                            
                                                <div className="div_purchase">
                                                    <div className="show_price">
                                                        {
                                                            elem.discount !='' ?
                                                                <>
                                                                    <span className="span_price">R$ { elem.price }</span>
                                                                    <span className="discount">R$ { elem.discount }</span>
                                                                </>
                                                            :
                                                            <span>R$ { elem.price }</span>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                <div className="div_purchase">
                                                    <div className="div_btn_course">
                                                        <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } } onClick={ ()=>{ RemovePurchase(elem.id); } }>
                                                            Remover curso
                                                        </div>

                                                        <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } } onClick={ ()=>{ OpenCourse(elem.id, elem.cover, elem.title, elem.text, elem.type_video, elem.video, elem.price, elem.discount) } }>
                                                            Informações sobre o curso
                                                        </div>
                                                    </div>
                                                    <div className="show_price hide_data">
                                                        {
                                                            elem.discount !='' ?
                                                                <>
                                                                    <span className="span_price">R$ { elem.price }</span>
                                                                    <span className="discount">R$ { elem.discount }</span>
                                                                </>
                                                            :
                                                            <span>R$ { elem.price }</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            : <div className="no_data">Nenhum dado encontrado...</div>
                        }
                    </div>

                    {
                        GetPurchase('purchase').length > 0 ?
                            <>
                                <div className="div_show_purchase">
                                    <div className="">
                                        Valor total: <span>{ price }</span>
                                    </div>
                                </div>

                                <div className="save_purchase" style={ { background: props.dataConfig['color_btn'] } } onClick={ ()=>{ CheckedLogin() } }>
                                    Finalizar compra
                                </div>
                            </>
                        : null
                    }
                </div>
                            
                {
                    showMsgPurchase ?
                        <div className="show_msg">
                            <div className="ld-ripple">
                                <div />
                                <div />
                            </div>
                            Informações registradas com sucesso, você será redirecionado para página de login
                        </div>
                    : null
                }
            </div>
            
            <Page_Contact dataConfig={ props.dataConfig } ReturnIndex={ props.ReturnIndex } setLoading={ props.setLoading } />
        </>
    )
}