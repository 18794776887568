import { useState, useEffect } from "react";

import './CoursesDetails.css';

import TextareaAutosize from 'react-textarea-autosize';

import Vimeo from '@u-wave/react-vimeo';

import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { colorIcon } from "fixedData";

import { Link } from "react-router-dom";

import { Reg_Contact, Reg_CourseWatched } from "services/Register";
import { Svg_Arrow, Svg_ChekedReturn, Svg_Download, Svg_Lock, Svg_Play } from "services/SvgFile";

export default function Page_CoursesDetails(props){

    const [ idModule, setIdModule ] = useState(GetListPag('currentPageIdModule'));
    const [ idClasse, setIdClasse ] = useState(GetListPag('currentPageIdClasse'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ typeVideo, setTypeVideo ] = useState(InitialData('type_video'));
    const [ video, setVideo ] = useState(InitialData('video'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ download, setDownload ] = useState(InitialData('download'));

    const [ infClasse, setInfClasse ] = useState(GetUserData('courses'));

    const [ listModule, setListModule ] = useState(ShowModule());
    const [ showInfCourse, setShowInfCourse ] = useState('text');

    /* page contact */
    const [ titleContact, setTitleContact ] = useState('');
    const [ msgContact, setMsgContact ] = useState('');
    /* end */

    /* time classe */
    const [ timeVideo, setTimeVideo ] = useState(0);
    const [ timeWatched, setTimeWatched ] = useState(0);
    const [ timePorcent, setTimePorcent ] = useState(0);
    /* end */

    function InitialData(type){
        const newData = GetDataPage('courses').find(item => item.id == props.idCourse);
        if(newData){
            if(idModule === 0){
                if(type === 'download'){
                    return [];
                }
                return newData[type];
            }else {
                const newModule = newData['classe'].find(item => item.id == idModule);
                if(newModule){
                    const newClasse = newModule['list'].find(item => item.id == idClasse);
                    if(newClasse){
                        if(type === 'title'){
                            return newData['title'] + " - " + newClasse['title'];
                        }else {
                            return newClasse[type];
                        }
                    }
                    if(type === 'download'){
                        return [];
                    }
                    return '';
                }
                if(type === 'download'){
                    return [];
                }
                return '';
            }
        }
        if(type === 'download'){
            return [];
        }
        return "";
    }

    /* count porcent video 94% */
    function CountPorcentVideo(){
        if(timeVideo !=0 || timeWatched !=0){
            let porcentTotal = timeVideo * 94 / 100;
            if(Math.round(timeWatched) > 0){
                if(Math.round(timeWatched) >= Math.round(porcentTotal)){                    
                    Reg_CourseWatched(GetUserData('id'), props.idCourse, GetListPag('currentPageIdModule'), GetListPag('currentPageIdClasse'), ()=>{ UpdateClasse() }); 
                }
            }
        }
    }
    function UpdateClasse(){
        setInfClasse(GetUserData('courses'));
    }
    /* end */

    function ShowModule(){
        const newData = GetDataPage('courses').find(item => item.id == props.idCourse);        
        if(newData){
            return newData['classe'];
        }
        return [];
    }

    function ShowVideo(){
        switch (typeVideo) {
            case 'Vimeo':
                let linkVimeo = video.split('/');
                return (
                    <div className="aspect_video">
                        <Vimeo className="div_video" width="100%" height="100%" onTimeUpdate={ (e)=>{ setTimeVideo(e.duration); setTimeWatched(e.seconds); setTimePorcent(e.percent) } } video={ linkVimeo[5] } />
                    </div>
                );
                
            case 'Youtube':
                let linkYoutube = video.split('=');
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
        }
    }

    function CourseProgress(type, id_module, id_classe){
        if(type === 'module'){
            const newData = [];
            GetUserData('courses').map((elem, index)=>{
                if(elem.id_course == props.idCourse){
                    elem.course_progress.map((elem_1, index_1)=>{
                        if(elem_1.id_module === id_module){
                            newData.push(elem_1);
                        }else { }
                    })
                }
            });        
            return newData.length;

        }else {
            let course = GetUserData('courses').find(item => item.id_course == props.idCourse);
            if(course){
                let module = course['course_progress'].filter(item => item.id_module == id_module);
                if(module){
                    let classe = module.find(item => item.id_classe == id_classe);
                    if(classe){
                        return(
                            <div className="icons_video">
                                <Svg_ChekedReturn className="icons" color="#006400" />
                            </div>
                        );
                    }
                    return "";
                }
                return "";
            }
            return "";
        }
    }

    function OpenMoodule(id, index, qtdClasse, status){
        const newData = [...listModule];
        newData[index]['status'] = status;
        setListModule(newData);

        let accordion = document.getElementById(id);
        if(status){
            accordion.style.maxHeight = (accordion.scrollHeight + (qtdClasse * 22) + 80) + "px";
            accordion.style.padding = "8px 8px";
            
        }else {
            accordion.style.maxHeight = null; 
            accordion.style.padding = "0px 8px";
        }
    }

    function OpenClasse(module, classe){
        SetListPag('currentPageIdModule', module);
        SetListPag('currentPageIdClasse', classe);
    }

    function CheckedClasse(id_module, id_classe, currentDate, dataClasse){
        let course = GetUserData('courses').find(item => item.id_course == props.idCourse);        
        if(course){
            const datePurchase = new Date(course['register_date']);

            // 1 - Immediate
            if(dataClasse['release_course'] === 1){                
                if(dataClasse['duration'] === 'Sim'){
                    let date = new Date(datePurchase);
                    date.setDate(date.getDate() + (parseInt(dataClasse['duration_qtd_day']) + 2));

                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    const dataLimit = year + "-" + month + "-" + day;
                    
                    if(currentDate > dataLimit){
                        return(
                            <div className="div_play">
                                <Svg_Lock className="icons" color={ colorIcon } />
                            </div>
                        )
                    }else {
                        return(
                            <div className="div_play" onClick={ ()=>{ OpenClasse(id_module, id_classe) } }>
                                <Svg_Play className="icons" color={ colorIcon } />
                            </div>
                        )
                    }
                }else {
                    return(
                        <div className="div_play" onClick={ ()=>{ OpenClasse(id_module, id_classe) } }>
                            <Svg_Play className="icons" color={ colorIcon } />
                        </div>
                    )
                }

            // 2 - Number of days
            }else if(dataClasse['release_course'] === 2){
                // date limit
                let dateLimit = new Date(datePurchase);
                dateLimit.setDate(dateLimit.getDate() + 8);

                const yearLimit = dateLimit.getFullYear();
                const monthLimit = String(dateLimit.getMonth() + 1).padStart(2, '0');
                const dayLimit = String(dateLimit.getDate()).padStart(2, '0');
                const dataLimitLimit = yearLimit + "-" + monthLimit + "-" + dayLimit;                

                if(dataClasse['duration'] === 'Sim'){
                    let date = new Date(datePurchase);
                    date.setDate(date.getDate() + (parseInt(dataClasse['duration_qtd_day']) + 2));

                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    const dateLimitDay = year + "-" + month + "-" + day;
                    
                    
                    if(currentDate > dateLimitDay || currentDate < dataLimitLimit){
                        return(
                            <div className="div_play">
                                <Svg_Lock className="icons" color={ colorIcon } />
                            </div>
                        )
                    }else {
                        return(
                            <div className="div_play" onClick={ ()=>{ OpenClasse(id_module, id_classe) } }>
                                <Svg_Play className="icons" color={ colorIcon } />
                            </div>
                        )
                    }
                }else {                    
                    if(currentDate < dataLimitLimit){
                        return(
                            <div className="div_play">
                                <Svg_Lock className="icons" color={ colorIcon } />
                            </div>
                        )
                    }else {
                        return(
                            <div className="div_play" onClick={ ()=>{ OpenClasse(id_module, id_classe) } }>
                                <Svg_Play className="icons" color={ colorIcon } />
                            </div>
                        )
                    }                    
                }
            }
            
        }else {
            return(
                <div className="div_play">
                    <Svg_Lock className="icons" color={ colorIcon } />
                </div>
            )
        }
    }

    /* send email*/
    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Contact('course', titleContact, msgContact, title, "", "", ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }
    function CallbackSuccess(){
        props.setLoading(false);
        setTitleContact('');
        setMsgContact('');

        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }
    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }  
    /* end */

    useEffect(()=>{
        RegisterListPag('currentPageIdModule', setIdModule);
        RegisterListPag('currentPageIdClasse', setIdClasse);

        return ()=>{
            UnRegisterListPag('currentPageIdModule', setIdModule);
            UnRegisterListPag('currentPageIdClasse', setIdClasse);
        };
    }, []);

    useEffect(()=>{
        if(typeVideo === 'Vimeo'){
            CountPorcentVideo();
        }
    }, [timeWatched]);

    useEffect(()=>{
        setTitle(InitialData('title'));
        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setText(InitialData('text'));
        setDownload(InitialData('download'));
    }, [idModule, idClasse]);

    return(
        <div className="page_content page_courses_details">
            <div className="container">
                <div className="title_course">
                    <Link to="/login" className="return" onClick={ ()=>{ props.ReturnIndex(); } }>
                        <Svg_Arrow className="icons" color={ colorIcon } />
                        Voltar
                    </Link>
                    <span>|</span>
                    <div className="">{ title }</div>
                </div>
                <div className="inf_details">
                    <div className="show_course">
                        { ShowVideo() }
                    </div>
                    <div className="list_module">
                        {
                            listModule.map((elem, index)=>{                                
                                return(
                                    <div className="show_module" key={ index }>
                                        <div className="module" onClick={ ()=>{ OpenMoodule("accordion_" + index, index, elem.list.length, !elem.status); } }>
                                            <div className="">{ elem.name }</div>
                                            <div className="">{ CourseProgress("module", elem.id, 0) }/{ elem.list.length }</div>
                                        </div>
                                        <div className="div_list_classes" id={ "accordion_" + index }>
                                            {
                                                elem.list.map((elem_1, index_1)=>{
                                                    return(
                                                        <div className={ idClasse === elem_1.id ? "show_inf_course course_id" : "show_inf_course" } key={ index_1 }>
                                                            <div className="title">
                                                                { CourseProgress("classe", elem.id, elem_1.id) }
                                                                <div className="">
                                                                    { elem_1.title }
                                                                </div>
                                                            </div>
                                                            {
                                                                CheckedClasse(elem.id, elem_1.id, elem_1.currentDate, elem_1)
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="show_details_course">
                    <div className="details_text" onClick={ ()=>{ setShowInfCourse('text') } }>
                        Descrição
                        {
                            showInfCourse === "text" ? <div className="opt_active" /> : null
                        }                        
                    </div>
                    {
                        download.length > 0 ? 
                            <div className="details_text" onClick={ ()=>{ setShowInfCourse('download') } }>
                                Download
                                {
                                    showInfCourse === "download" ? <div className="opt_active" /> : null
                                }
                            </div>
                        : null
                    }
                </div>
                <div className="show_details_course">
                    {
                        showInfCourse === "text" ?
                        <div className="div_text" dangerouslySetInnerHTML={ { __html: text.replaceAll('&#34;', '"') } } />
                        :
                        <div className="list_download">
                            {
                                download.map((elem, index)=>{
                                    let extAlt = elem.file.split('/');
                                    let ext = extAlt[6].split('.');

                                    return(
                                        <div className="show_download" key={ index }>
                                            <div className="div_title">{ elem.title }</div>
                                            <a href={ elem.file } download={ elem.title + "." + ext[1] } target="_blank" className="div_title">
                                                <Svg_Download className="icons" color={ colorIcon } />
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>

            <div className="div_page_msg_course" style={ { background: props.dataConfig['color_contact'] } }>
                <div className="container">
                    <div className="title_page">Dúvidas, elogios e sugestões sobre o curso</div>
                    <div className="show_data_contact">
                        <div className="div_form">
                            <form className="form" onSubmit={ SaveData }>
                                <div className="wave-group">
                                    <input type="text" className="input" onChange={ (e)=>{ setTitleContact(e.target.value) } } value={ titleContact } required />
                                    <span className="bar"></span>
                                    <label className="label">
                                        <span className="label-char" style={ { '--index': 0 } }>T</span>
                                        <span className="label-char" style={ { '--index': 1 } }>í</span>
                                        <span className="label-char" style={ { '--index': 2 } }>t</span>
                                        <span className="label-char" style={ { '--index': 3 } }>u</span>
                                        <span className="label-char" style={ { '--index': 4 } }>l</span>
                                        <span className="label-char" style={ { '--index': 5 } }>o</span>
                                    </label>
                                </div>

                                <div className="wave-group">
                                    <TextareaAutosize className="input textarea" onChange={ (e)=>{ setMsgContact(e.target.value) } } value={ msgContact.replace(/<br>/g,'\n') } required />

                                    <span className="bar"></span>
                                    <label className="label">
                                        <span className="label-char" style={ { '--index': 0 } }>M</span>
                                        <span className="label-char" style={ { '--index': 1 } }>e</span>
                                        <span className="label-char" style={ { '--index': 2 } }>n</span>
                                        <span className="label-char" style={ { '--index': 3 } }>s</span>
                                        <span className="label-char" style={ { '--index': 4 } }>a</span>
                                        <span className="label-char" style={ { '--index': 5 } }>g</span>
                                        <span className="label-char" style={ { '--index': 6 } }>e</span>
                                        <span className="label-char" style={ { '--index': 7 } }>m</span>
                                    </label>
                                </div>

                                <div className="div_btn">
                                    <button type="submit" className="btn" style={ { background: props.dataConfig['color_btn'] } }>Enviar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}