import Axios from 'axios';

import Cookies from 'universal-cookie';

import { cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePageIdClasse, typeCookiePageIdModule, typeCookiePassw, urlPhp } from 'fixedData';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';

export function Access(type, reg_email, reg_pass, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_post', 'logar');
    data.append('type', type);
    data.append('email', reg_email);
    data.append('password', reg_pass);

    Axios({
        url : urlPhp + 'inc/login_site.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {              
        switch(response.data.type) {
            case 'Connected':
                    // current date
                    const day = new Date();

                    // add 3 day
                    const add3Days = new Date();
                    add3Days.setDate(day.getDate() + 3);
                    
                    cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);
                    cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);

                    // checked cookie page id course
                    if(cookies.get(typeCookiePageId)){
                        SetListPag('josemery_site_id', cookies.get(typeCookiePageId));
                    }

                    // checked cookie page id module and classe
                    if(cookies.get(typeCookiePageIdModule)){
                        SetListPag('josemery_site_id_module', cookies.get(typeCookiePageIdModule));
                        SetListPag('josemery_site_id_classe', cookies.get(typeCookiePageIdClasse));
                    }

                    SetUserData('id', response.data.id);
                    SetUserData('name', response.data.name);
                    SetUserData('email', response.data.email);
                    SetUserData('cpf', response.data.cpf);
                    SetUserData('file', response.data.file);
                    SetUserData('phone', response.data.phone); 
                    SetUserData('cep', response.data.cep); 
                    SetUserData('city', response.data.city); 
                    SetUserData('state', response.data.state); 
                    SetUserData('neighborhood', response.data.neighborhood); 
                    SetUserData('street', response.data.street); 
                    SetUserData('number', response.data.number); 
                    SetUserData('complement', response.data.complement); 

                    SetUserData('courses', response.data.courses); 

                    CallbackSuccess();
                break;

            default:
                    CallbackError();
                    cookies.remove(typeCookieEmail, "", '/', cookiesRegister);
                    cookies.remove(typeCookiePassw, "", '/', cookiesRegister);
                break;
        }

    }).catch((error)=>{ 
        console.log(error);
          
        CallbackError();
        cookies.remove(typeCookieEmail, "", '/', cookiesRegister);
        cookies.remove(typeCookiePassw, "", '/', cookiesRegister);
    })
}
