import Axios from 'axios';

import Cookies from 'universal-cookie';

import { cookiesRegister, typeCookieEmail, typeCookiePassw, urlPhp } from 'fixedData';

import { SetUserData } from 'interface/Users';

export function Reg_Contact(type, name, email, phone, toKnow, text, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append("type_page", 'contact');
    data.append("type_email", type);

    if(type === 'course'){
        data.append('title_contact', name);
        data.append('msg_contact', email);
        data.append('name_course', phone);

    }else {
        data.append('name', name);
        data.append('email', email);
        data.append('phone', phone);
        data.append('to_know', toKnow);
        data.append('text', text);
    }

    Axios({
        url : urlPhp + 'email/contact.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        if(response.data === 'Connected'){
            CallbackSuccess();
        }else {
            CallbackError();
        }
    }).catch((error)=>{
        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url : urlPhp + 'email/recover_pass_site.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);        
        switch (response.data) {
            case "Email sent successfully": case "Connected": CallbackSuccess(); break;
            case "Error sending message": case "Error": CallbackError_Send(); break;
            case "Email not sent": case "Error": CallbackError_NotEmail(); break;
            case "Email not found": case "Error": CallbackError(); break;
            default: CallbackError(); break;
        }

    }).catch((error)=>{
        console.log(error);        
        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url : urlPhp + 'email/recover_pass_site.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);        
        switch (response.data) {
            case "Email successfully sent": case "Connected":
                    CallbackSuccess(); 
                break;
            default: 
                    CallbackError(); 
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Save data user
export function Reg_SaveUser(id, file, name, email, cpf, pass, phone, cep, city, state, neighborhood, street, number, complement, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', "students");
    data.append('edit_data', "edit_or_register");
    data.append('id', id);
    data.append('file', file);
    data.append('name', name);
    data.append('email', email);
    data.append('cpf', cpf);
    data.append('pass', pass);
    data.append('phone', phone);
    data.append('cep', cep);
    data.append('city', city);
    data.append('state', state);
    data.append('neighborhood', neighborhood);
    data.append('street', street);
    data.append('number', number);
    data.append('complement', complement);

    Axios({
        url : urlPhp + 'page/students_site.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);        
        switch(response.data.type) {
            case 'Connected':                    
                    // current date
                    const day = new Date();

                    // add 3 day
                    const add3Days = new Date();
                    add3Days.setDate(day.getDate() + 3);

                    cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);
                    
                    if(pass !=''){
                        cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
                    }

                    SetUserData('id', response.data.id);
                    SetUserData('name', response.data.name);
                    SetUserData('email', response.data.email);
                    SetUserData('file', response.data.file);
                    SetUserData('phone', response.data.phone); 
                    SetUserData('cep', response.data.cep); 
                    SetUserData('city', response.data.city); 
                    SetUserData('state', response.data.state); 
                    SetUserData('neighborhood', response.data.neighborhood); 
                    SetUserData('street', response.data.street); 
                    SetUserData('number', response.data.number); 
                    SetUserData('complement', response.data.complement);
                    SetUserData('courses', response.data.courses);
                    CallbackSuccess();
                break;

            default:
                    CallbackError();
                    cookies.remove(typeCookieEmail, "", '/', cookiesRegister);
                    cookies.remove(typeCookiePassw, "", '/', cookiesRegister);
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Register purchase
export function Reg_Purchase(id, listPurchase, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', "purchase");
    data.append('edit_data', "edit_or_register");
    data.append('id_user', id);
    
    listPurchase.forEach((elem, index) => {
        data.append('course_id[]', elem.id);
        data.append('course_title[]', elem.title);
        data.append('course_text[]', elem.text);
        data.append('course_discount[]', elem.discount);
        data.append('course_price[]', elem.price);
    });

    Axios({
        url : urlPhp + 'page/purchase.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data, id, listPurchase);
        switch(response.data.type) {
            case 'Connected':
                    SetUserData('id', response.data.id);
                    SetUserData('name', response.data.name);
                    SetUserData('email', response.data.email);
                    SetUserData('file', response.data.file);
                    SetUserData('phone', response.data.phone); 
                    SetUserData('cep', response.data.cep); 
                    SetUserData('city', response.data.city); 
                    SetUserData('state', response.data.state); 
                    SetUserData('neighborhood', response.data.neighborhood); 
                    SetUserData('street', response.data.street); 
                    SetUserData('number', response.data.number); 
                    SetUserData('complement', response.data.complement);
                    SetUserData('courses', response.data.courses);
                    CallbackSuccess();
                break;

            default:
                    CallbackError();
                    cookies.remove(typeCookieEmail, "", '/', cookiesRegister);
                    cookies.remove(typeCookiePassw, "", '/', cookiesRegister);
                break;
        }

    }).catch((error)=>{
        console.log(error);
        
        CallbackError();
    })
}

// Register course - watched
export function Reg_CourseWatched(id, idCourse, idModule, idClasse, CallbackSuccess){
    const data = new FormData();

    data.append('type_page', "purchase");
    data.append('edit_data', "register_watched_classe");
    data.append('id_user', id);
    data.append('id_course', idCourse);
    data.append('id_module', idModule);
    data.append('id_classe', idClasse);

    console.log(id, idCourse, idModule, idClasse);    

    Axios({
        url : urlPhp + 'page/purchase.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetUserData('courses', response.data.courses);

    }).catch((error)=>{
        console.log(error);
    })
}