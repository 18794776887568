import React, { useState, useEffect } from "react";

import './ReturnResponse.css';

import { useNavigate } from "react-router-dom";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { Svg_ChekedReturn, Svg_Erro } from "services/SvgFile";

export default function PopUP_ReturnResponse(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const navigate = useNavigate();

    function ClosePopUp(){        
        if(modalData.type === "login"){
            navigate("/login");
        }
        SetModalState('ReturnResponse', false);
    }

    function TypeData(){
        switch (modalData.page) {
            case 'missingData':
                return(
                    <>
                        <Svg_ChekedReturn color="#ffb500" className="icons_return" />
                        <div className="title_return">
                            { modalData.text }
                        </div>
                    </>
                )

            case 'erro':
                return(
                    <>
                        <Svg_Erro color="#F00000" className="icons_return" />
                        <div className="title_return">
                            {
                                modalData.text == "" ? "Erro ao enviar msg, favor entrar em contato no número (41) 99161-7078" : modalData.text
                            }                            
                        </div>
                    </>
                )

            default:
                return(
                    <>
                        <Svg_ChekedReturn color="#2d3c53" className="icons_return" />
                        <div className="title_return">
                            {
                                modalData.text == "" ? "Mensagem enviada com sucesso, entraremos em contato com o senhor(a) o mais breve possível" : modalData.text
                            }                            
                        </div>
                    </>
                )
        }
    }

    useEffect(()=>{
        RegisterModalData('ReturnResponse', setModaldata);
        RegisterModalObserver('ReturnResponse', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            document.body.style.overflow = "hidden";
        }else {               
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    return(
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all ReturnResponse">
                    <div className="div_data">
                        <div className="content">
                            {
                                TypeData()
                            }
                            <div className="close_return" onClick={ ()=>{ ClosePopUp() } }>
                                Fechar
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    )
};
