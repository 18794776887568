import React, { useState, useEffect, useRef } from "react";

import './CheckedLogin.css';

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { colorIcon } from "fixedData";

import { Access } from "services/Access";
import { Reg_Purchase, Reg_SaveUser } from "services/Register";
import { cepMask, cpfMask, phoneMask } from "services/Mask";
import { Svg_ArrowRight, Svg_Close, Svg_Eye, Svg_EyeClose } from "services/SvgFile";
import { GetUserData } from "interface/Users";
import { GetPurchase, SetPurchaseSingle } from "interface/Purchase";
import { useNavigate } from "react-router-dom";

export default function PopUP_CheckedLogin(props){

    const navigate = useNavigate();

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ showMsg, setShowMsg ] = useState(false);

    const [ optActive, setOptActive ] = useState('login');
    
    function ClosePopUp(){
        SetModalData('CheckedLogin', {});
        SetModalState('CheckedLogin', false);
    }

    /* login */
    const divShowHidePassword = useRef();
    const [ statusShowHidePassword, setStatusShowHidePassword ] = useState(false);
    const [ login_email, setLogin_Email ] = useState('');
    const [ login_password, setLogin_Password ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function ShowHidePass(status){
        setStatusShowHidePassword(status);
        divShowHidePassword.current.type = statusShowHidePassword ? 'password' : 'text';
    }    
    function RecoverAccess(){
        SetModalState('RecoverEmail', true);
    }
    function SaveDataLogin(event){
        event.preventDefault();
        props.setLoading(true);
        Access('logar', login_email, login_password, ()=>{ CallbackSuccess(); props.setStatusLogin(true) }, ()=>{ CallbackErrorLogin(); props.setStatusLogin(false); });
    }
    function CallbackErrorLogin(){
        props.setLoading(false);
        setMsgError('E-mail ou senha incorreta!');
    }
    /* end */

    /* register */
    const [ register_file, setRegister_File ] = useState('');
    const [ register_fileStatus, setRegister_FileStatus ] = useState(false);
    const [ register_name, setRegister_Name ] = useState('');
    const [ register_email, setRegister_Email ] = useState('');
    const [ register_cpf, setRegister_Cpf ] = useState('');
    const [ register_pass, setRegister_Pass ] = useState('');
    const [ register_phone, setRegister_Phone ] = useState('');
    const [ register_cep, setRegister_Cep ] = useState('');
    const [ register_city, setRegister_City ] = useState('');
    const [ register_state, setRegister_State ] = useState('');
    const [ register_neighborhood, setRegister_Neighborhood ] = useState('');
    const [ register_street, setRegister_Street ] = useState('');
    const [ register_number, setRegister_Number ] = useState('');
    const [ register_complement, setRegister_Complement ] = useState('');

    function AddFile(value){
        if(value){
            setRegister_File(value);
            setRegister_FileStatus(true);
        }else {
            setRegister_File('');
            setRegister_FileStatus(false);
        }
    }
    function SaveDataRegister(event){
        props.setLoading(true);        
        event.preventDefault();
        Reg_SaveUser(0, register_file, register_name, register_email, register_cpf, register_pass, register_phone, register_cep, register_city, register_state, register_neighborhood, register_street, register_number, register_complement, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }
    /* end */
    
    function CallbackSuccess(){
        props.setLoading(false);
        Reg_Purchase(GetUserData('id'), GetPurchase('purchase'), ()=>{ AltPage() }, ()=>{ CallbackError() });   
    }

    function AltPage(){
        setShowMsg(true);
        setTimeout(() => {
            setShowMsg(false);
            navigate("/login");
            SetPurchaseSingle('remuve_all', '', '', '', '', '', '');
            ClosePopUp();
        }, 1500);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "Erro ao registrar informações!" });
        SetModalState('ReturnResponse', true);
    }

    function ShowOptLogin(){
        if(optActive === 'login'){
            return(
                <form className="page_content page_login" onSubmit={ SaveDataLogin }>
                    <div className="div_data_login">
                        <div className="show_data_login">
                            <div className="name_input">E-mail</div>
                            <div className="div_input">
                                <input type="email" onChange={ (e)=>{ setLogin_Email(e.target.value.replaceAll(' ', '')) } } value={ login_email } required placeholder="digite seu e-mail..." />
                            </div>
                            <div className="name_input">Senha</div>
                            <div className="div_input">
                                <input type="password" onChange={ (e)=>{ setLogin_Password(e.target.value.replaceAll(' ', '')) } } value={ login_password } ref={ divShowHidePassword } required placeholder="digite sua senha..." />
                                <div className="div_eye" onClick={ ()=>{ ShowHidePass(!statusShowHidePassword) } }>
                                    {
                                        statusShowHidePassword ?
                                        <Svg_Eye className="icons" color={ colorIcon } /> :
                                        <Svg_EyeClose className="icons" color={ colorIcon } />
                                    }
                                </div>
                            </div>
                            {
                                msgError ? <div className="div_input error">{ msgError }</div> : null
                            }
                            <div className="type_save">
                                <div className="div_recover register" onClick={ ()=>{ RecoverAccess(); } }>
                                Esqueceu a senha?
                                </div>
                                
                                <button type="submit" className="div_btn" style={ { background: props.dataConfig['color_btn'] } }>
                                    <div className="">Logar</div>
                                    <div className="">
                                        <Svg_ArrowRight className="icons" color="#ffffff" />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )

        }else if(optActive === 'register'){
            return(
                <div className="page_content page_register">
                    <form className="" onSubmit={ SaveDataRegister }>
                        <div className="div_inf_user">
                            <div className="div_show_inf">
                                <div className="type_data">
                                    <div className="name_type_data">Seus dados</div>
                                    <div className="show_inf_input">
                                        <div className="div_input space_div">
                                            <div className="name_input">Nome*</div>
                                            <input type="text" onChange={ (e)=>{ setRegister_Name(e.target.value) } } value={ register_name } required={ true } />
                                        </div>
                                        <div className="div_input space_div">
                                            <div className="name_input">E-mail*</div>
                                            <input type="text" onChange={ (e)=>{ setRegister_Email(e.target.value.replaceAll(' ', '')) } } value={ register_email } required={ true } />
                                        </div>
                                        <div className="div_input">
                                            <div className="name_input">CPF*</div>
                                            <input type="text" className="cpf" onChange={ (e)=>{ setRegister_Cpf(e.target.value) } } value={ cpfMask(register_cpf) } required={ true } />
                                        </div>
                                        <div className="div_input">
                                            <div className="name_input">Contato*</div>
                                            <input type="text" className="phone" onChange={ (e)=>{ setRegister_Phone(e.target.value) } } value={ phoneMask(register_phone) } required={ true } />
                                        </div>
                                        <div className="div_input">
                                            <div className="name_input">Senha*</div>
                                            <input type="password" className="pass" onChange={ (e)=>{ setRegister_Pass(e.target.value) } } value={ register_pass } required={ true } />
                                        </div>
                                    </div>
                                </div>
                                <div className="type_data">
                                    <div className="name_type_data">Endereço</div>
                                    <div className="show_inf_input">
                                        <div className="div_input">
                                            <div className="name_input">CEP</div>
                                            <input type="text" className="cep" onChange={ (e)=>{ setRegister_Cep(e.target.value)  } } value={ cepMask(register_cep) } required={ false } placeholder="xxxxx-xx" />
                                        </div>
                                    </div>
                                    <div className="show_inf_input">
                                        <div className="div_input space_div">
                                            <div className="name_input">Cidade</div>
                                            <input type="text" onChange={ (e)=>{ setRegister_City(e.target.value) } } value={ register_city } maxLength={ 140 } required={ false } />
                                        </div>
                                        <div className="div_input">
                                            <div className="name_input">UF</div>
                                            <input type="text" className="uf" onChange={ (e)=>{ setRegister_State(e.target.value.replaceAll(' ', '')) } } value={ register_state } maxLength={ 3 } required={ false } />
                                        </div>
                                        <div className="div_input space_div">
                                            <div className="name_input">Bairro</div>
                                            <input type="text" className="" onChange={ (e)=>{ setRegister_Neighborhood(e.target.value) } } value={ register_neighborhood } maxLength={ 140 } required={ false } />
                                        </div>
                                        <div className="div_input space_div">
                                            <div className="name_input">Rua</div>
                                            <input type="text" className="" onChange={ (e)=>{ setRegister_Street(e.target.value) } } value={ register_street } maxLength={ 140 } required={ false } />
                                        </div>
                                        <div className="div_input">
                                            <div className="name_input">Número</div>
                                            <input type="text" className="number" onChange={ (e)=>{ setRegister_Number(e.target.value) } } value={ register_number } maxLength={ 140 } required={ false } placeholder="xxxxxx" />
                                        </div>
                                    </div>
                                    <div className="show_inf_input">
                                        <div className="div_input space_div">
                                            <div className="name_input">Complemento</div>
                                            <input type="text" className="" onChange={ (e)=>{ setRegister_Complement(e.target.value) } } value={ register_complement } required={ false } />
                                        </div>
                                    </div>
                                </div>
                                <button className="btn_save" style={ { background: props.dataConfig['color_btn'] } }>Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            )
        }
    }

    useEffect(()=>{
        RegisterModalData('CheckedLogin', setModaldata);
        RegisterModalObserver('CheckedLogin', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            document.body.style.overflow = "hidden";
        }else {
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div>
                <div className="PopUp">
                    <div className="all CheckedLogin">
                        <div className="div_data type_div">
                            <div className="title">
                                É necessário efetuar login para finalizar a compra
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <Svg_Close color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="div_opt_access">
                                <div className={ optActive === "login" ? "div_btn line_bottom" : "div_btn" } onClick={ ()=>{ setOptActive('login') } }>
                                    Login                                  
                                </div>
                                <div className={ optActive === "register" ? "div_btn line_bottom" : "div_btn" } onClick={ ()=>{ setOptActive('register') } }>
                                    Novo cadastro
                                </div>
                            </div>
                            {
                                ShowOptLogin()
                            }
                        </div>
                        
                        {
                            showMsg ?
                                <div className="show_msg">
                                    <div className="ld-ripple">
                                        <div />
                                        <div />
                                    </div>
        
                                    Informações registradas com sucesso, você será redirecionado para página de login
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
